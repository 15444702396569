import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, Button, Link, IconButton, Stepper, Step, StepLabel, Alert, Modal, Fab } from '@mui/material';
import { Typewriter } from 'react-simple-typewriter';
import Footer from './components/Footer';
import backgroundImage from './assets/Luxembourg.jpeg';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import logo from './assets/pnglogo.png';
function App() {

  // Main state to hold trip data throughout the steps

  return (
    <ThemeProvider theme={theme}>


      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        sx={{
          backgroundImage: `
                  linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), 
                  url(${backgroundImage})
              `,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          color: '#FFFFFF',

          backgroundColor: '#e7e8f3'
        }}
      >


        <Box
          sx={{
            position: "fixed",
            display: { xs: 'block', md: 'none' },
            bottom: "10px", // Adjust as needed
            left: "10px", // Adjust as needed
            zIndex: 1000, // Ensure it's on top of other elements
          }}
        >
          <Fab
            color="success"
            href="https://wa.me/352661392929" // Replace with your WhatsApp link
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              backgroundColor: "#25D366",
              "&:hover": {
                backgroundColor: "#1EBE54",
              },
            }}
          >
            <WhatsAppIcon />
          </Fab>
        </Box>

        {/* Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-around', p: 1, m: 1 }}>
          <Typography
            component="img"
            src={logo}
            alt="Company Logo"
            sx={{
              height: 60,
              width: 'auto',
              objectFit: 'contain',
              maxWidth: '150px'
            }}
          />
          <Link href="https://wa.me/352661392929" target="_blank" underline="none" sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: 5, display: { xs: 'none', md: 'block' }, }}>
            <IconButton sx={{ color: 'green' }}>
              <WhatsAppIcon />
              <Typography variant="body1" sx={{ textAlign: 'center', padding: '10px', fontWeight: 'bold' }}>
                +352 661 39 29 29
              </Typography>
            </IconButton>
          </Link>
        </Box>

        {/* Main Content */}
        <Container component="main" sx={{ flexGrow: 1, my: 4, display: 'flex', overflow: 'hidden', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

          <Typography variant="h3" sx={{ textAlign: 'center', fontWeight: 'bold', mt: -20, color: '#5b6df7', width: '100%' }}>
            TripGo<br></br> {' '}
            <span style={{ color: '#fff', fontSize: 32, color: '#ef4a67' }}>
              <Typewriter
                words={['Coming Soon!']}
                loop={false}
                cursor
                cursorStyle="|"
                typeSpeed={100}
                deleteSpeed={50}
                delaySpeed={10000}
              />
            </span>
          </Typography>

        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
